import general from "./general/general.json";
import dataTable from "./general/dataTable.json";

import forum from "./community/forum.json";

// User Views
import members from "./userViews/members.json";
import accountManagerView from "./userViews/account_manager_view.json";

// 121 Meetings
import meetings from "./meetings/meetings.json";

export default function initializeGermanTranslation() {
  let germanTranslation = {
    general,
    dataTable,
    community: { forum },
    meetings: { meetings },
    userViews: { members, accountManagerView },
  };

  return germanTranslation;
}
