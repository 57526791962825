import { createRouter, createWebHistory } from "vue-router";
// import CheckAuthenticationState from "@/helpers/authentication/CheckAuthenticationState.js";

/**
 * eventOS sites
 */

//import notFound from "@/views/eventos/404"

/**
 * Organization Level
 */

const initiate_organization = () => import("@/views/initiate_organization");
// const UserDetails = () => import('./views/UserDetails.vue')

// import orgOverview from "@/views/organization/org_overview";

const noOrg = () => import("@/views/organization/noOrg");

/**
 * Organization system pages
 */

// Forums
const forum = () => import("@/views/organization/system_pages/forum/forum.vue");

// User Views
const memberDirectory = () => import("@/views/organization/system_pages/user_views/member_directory.vue");

/**
 * Conference Level
 */

const pageMain = () => import("@/views/website/main");

// Authentication, Login + Registration
// import login from "@/views/conference/auth/login";
const register = () => import("@/views/conference/auth/register.vue");
// import passwordForgotten from "@/views/conference/auth/passwordForgotten.vue";
// import resetPassword from "@/views/conference/auth/resetPassword.vue";
// import declineAttendance from "@/views/conference/auth/declineAttendance.vue";

// Customizable pages
// import conferenceOverview from "@/views/conference/customizable/landing_page";
// import home from "@/views/conference/customizable/home.vue";
const page = () => import("@/views/conference/customizable/page.vue");
// import plenary from "@/views/conference/customizable/plenary.vue";

/**
 * Conference system pages
 */

// 121
const holderdirectory121 = () => import("@/views/conference/system_pages/121/121_holder_directory.vue");
const requestBooking = () => import("@/views/conference/system_pages/121/request.vue");
const basket121 = () => import("@/views/conference/system_pages/121/basket.vue");
const meetings121 = () => import("@/views/conference/system_pages/121/meetings.vue");

// Workshops
const workshopBasket = () => import("@/views/conference/system_pages/workshops/basket");

// User Views
const accountManagerView = () => import("@/views/conference/system_pages/user_views/account_manager_view.vue");

// Other
const contactEventTeam = () => import("@/views/conference/system_pages/other/contact_event_team");
const loading = () => import("@/views/conference/system_pages/other/loading");

/**
 * Routes
 */

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "Default",
    beforeEnter: () => {
      window.location.replace("https://about.event-os.net");
    },
  },
  {
    path: "/:organizationShortCode",
    name: "organizationBase",
    component: initiate_organization,
    children: [
      {
        path: "/:organizationShortCode/:conferenceShortCode",
        name: "pageBase",
        component: pageMain,
        children: [
          {
            path: "",
            name: "conferenceOverview",
            component: page,
          },
          //  {
          //   path: "login",
          //   name: "login",
          //   component: pageMain,
          //   meta: { registerContext: false },
          //  },
          // {
          //   path: "passwordForgotten",
          //   name: "passwordForgotten",
          //   component: passwordForgotten,
          // },
          // {
          //   path: "otp",
          //   name: "registerOTP",
          //   component: login,
          //   meta: { registerContext: true },
          // },
          {
            path: "otp/:otp_matchphrase/:resetpassword/",
            name: "registerOTPReset",
            component: page,
            props: true,
          },
          {
            path: "magic/:magic_matchphrase/:resetpassword",
            name: "magicLinkReset",
            component: page,
            props: true,
          },
          {
            path: "decline/:decline_matchphrase/:resetpassword",
            name: "declineReset",
            component: page,
            props: true,
          },
          {
            path: "contact",
            name: "contactPage",
            component: contactEventTeam,
          },
          {
            path: "loading",
            name: "loading",
            component: loading,
          },
          {
            path: "register",
            name: "register",
            component: register,
          },
          {
            path: "register/:area",
            name: "registerWithArea",
            component: register,
            props: true,
          },
          // {
          //   path: "reset",
          //   name: "reset",
          //   component: resetPassword,
          // },
          // {
          //   path: "decline",
          //   name: "decline",
          //   component: declineAttendance,
          // },
          {
            path: "home",
            name: "home",
            redirect: { name: "conferenceOverview" },
          },
          {
            path: "page/:pageShortlink",
            name: "dynPage",
            component: page,
            props: true,
          },
          // {
          //   path: "plenary",
          //   name: "plenaryRedirect",
          //   redirect: { name: "home" },
          // },
          // {
          //   path: "plenary/:id",
          //   name: "plenary",
          //   component: plenary,
          //   props: true,
          // },

          // 121
          {
            path: "121/holderdirectory",
            name: "HolderDirectory",
            component: holderdirectory121,
          },
          {
            path: "121/request/:holder",
            name: "RequestNewMeeting",
            component: requestBooking,
            props: true,
          },
          {
            path: "121/request/change/:id",
            name: "ChangeExistingMeeting",
            component: requestBooking,
            props: true,
          },

          {
            path: "121/mybasket",
            name: "121Basket",
            component: basket121,
          },
          {
            path: "121/mymeetings",
            name: "121Meetings",
            component: meetings121,
          },

          // Community
          {
            path: "forums",
            name: "forums",
            component: forum,
            props: true,
          },
          {
            path: "forum/:forum",
            name: "SingleForum",
            component: forum,
            props: true,
          },
          {
            path: "forum/:forum/:thread",
            name: "SingleThread",
            component: forum,
            props: true,
          },

          // Member Directory
          {
            path: "members",
            name: "MemberDirectory",
            component: memberDirectory,
            props: true,
          },

          // Account Manager View
          {
            path: "account_manager",
            name: "AccountManagerView",
            component: accountManagerView,
            props: true,
          },

          // Workshops
          {
            path: "workshops/my",
            name: "WorkshopBasket",
            component: workshopBasket,
          },

          // OTP Mode

          {
            path: "otp/:resetemail/:resetpassword/:status",
            name: "OneTimePasswordRoute",
            component: pageMain,
            props: true,
          },

          // Debug Mode
          {
            path: "d/:debugSessionId",
            name: "DebugSessionMode",
            component: pageMain,
            props: true,
          },
        ],
        props: true,
      },
    ],
    props: true,
  },
  {
    path: "/unknown",
    name: "noOrg",
    component: noOrg,
  },
];

const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  } else {
    const position = {};

    // scroll to anchor by returning the selector
    if (to.hash) {
      position.selector = to.hash;

      // bypass #1number check
      if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
        console.log("position");
        return position;
      }
      return false;
    }

    return { top: 0 };
  }
};

const router = createRouter({
  routes,
  scrollBehavior,
  history: createWebHistory(process.env.BASE_URL),
});

export default router;
